<template>
  <div id="app" class="website">
    <div id="header" class="header" :class="{'fixed': fixedFlag}">
      <div class="container">
        <div class="logo"></div>
        <div class="nav">
          <div class="item" v-for="(item, index) in nav" v-html="item.title"
               @click="onClickNav(index)"></div>
        </div>
      </div>
    </div>
    <div id="first" class="first-block">
      <div class="container">
        <div class="title">要购物·到上海</div>
        <div class="subtitle">网罗上海最新最全的购物时尚信息<br/>购物、美食、潮流、文化，上海一站式购物服务平台</div>
        <div class="go" @click="onClickNav(3)">前往体验</div>
      </div>
    </div>
    <div id="second" class="second-block">
      <div class="container">
        <img class="title" src="./resources/image/second_title.png" alt="平台介绍"/>
        <div class="content">
          <img class="left" src="./resources/image/second_cover.png" alt="平台介绍"/>
          <div class="right">
            <img class="title" src="./resources/image/second_subtitle.png" alt="平台介绍"/>
            <div class="desc four-line-ellipsis">上海购物APP，是一站式、多功能、国际化的消费资讯信息门户和上海购物品牌宣传平台，向用户提供最精致实用的本市消费指南。</div>
          </div>
        </div>
      </div>
    </div>
    <div id="third" class="third-block"></div>
    <div id="fourth" class="fourth-block">
      <div class="container">
        <img class="title" src="./resources/image/fourth_title.png" alt="产品体验"/>
        <div class="platform">
          <!--<div class="item">
              <div class="grid">
                  <div class="name">要购物·到上海</div>
                  <img class="logo" src="./resources/image/android_logo.png" alt="Android"/>
                  <img class="qrcode" src="./resources/image/android_qrcode.png" alt="Android"/>
              </div>
              <div class="way">Android下载</div>
              <div class="desc">前往应用宝下载体验</div>
          </div>-->
          <div class="item">
            <div class="grid">
              <div class="name">要购物·到上海</div>
              <img class="logo" src="./resources/image/ios_logo.png" alt="iOS"/>
              <img class="qrcode" :src="download.qrcode" alt="iOS"/>
            </div>
            <div class="way">iPhone下载</div>
            <div class="desc">前往App Store下载体验</div>
          </div>
          <!--<div class="item">
              <div class="grid">
                  <div class="name">要购物·到上海</div>
                  <img class="logo" src="./resources/image/wechat_logo.png" alt="WeChat"/>
                  <img class="qrcode" src="./resources/image/wechat_qrcode.png" alt="WeChat"/>
              </div>
              <div class="way">小程序体验</div>
              <div class="desc">扫码体验</div>
          </div>-->
        </div>
      </div>
    </div>
    <!--<div id="fifth" class="fifth-block">
        <div class="container">
            <img class="title" src="./resources/image/fifth_title.png" alt="媒体报道"/>
            <div class="media">
                <div class="item" v-for="(item, index) in media"
                     :class="{'border': (Math.ceil((index + 1) / 3) < Math.ceil(media.length / 3))}">
                    <div class="cover" :style="{'backgroundImage': 'url(' + item.cover + ')'}"></div>
                    <div class="title one-line-ellipsis" v-html="item.title"></div>
                    <div class="content three-line-ellipsis" v-html="item.content"></div>
                    <div class="bottom">
                        <div class="original" @click="onClickOriginal(item)"></div>
                        <div class="date" v-html="item.date"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
    <div class="footer">
      <div class="copyright">Copyright © 2020 shopping-shanghai.com All Rights Reserved</div>
      <div class="copyright">
        <a class="gongan" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402008548">沪公网安备31010402008548号</a> <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备16045598号-6</a>
      </div>
      <div class="copyright">上海市商务发展研究中心 版权所有</div>
      <div class="copyright">上海市商务发展研究中心 出品</div>
    </div>
  </div>
</template>
<style src="./styles/common.css"/>
<style src="./styles/app.css"/>
<script>
import config from "../../seven/src/config";

export default {
  data() {
    return {
      download: {},
      nav: [{
        id: 'first',
        title: '首页'
      }, {
        id: 'second',
        title: '平台介绍'
      }, {
        id: 'third',
        title: '特色功能'
      }, {
        id: 'fourth',
        title: '产品体验'
        /*}, {
            id: 'fifth',
            title: '媒体报道'*/
      }],
      selectedNav: 0,
      fixedFlag: false,
      media: [{
        cover: require('./resources/image/1.png'),
        title: '上海购物APP于2020年正式发布',
        content: '上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布',
        date: '2020·01·01'
      }, {
        cover: require('./resources/image/2.png'),
        title: '上海购物APP于2020年正式发布',
        content: '上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布',
        date: '2020·01·01'
      }, {
        cover: require('./resources/image/1.png'),
        title: '上海购物APP于2020年正式发布',
        content: '上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布',
        date: '2020·01·01'
      }, {
        cover: require('./resources/image/2.png'),
        title: '上海购物APP于2020年正式发布',
        content: '上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布',
        date: '2020·01·01'
      }, {
        cover: require('./resources/image/1.png'),
        title: '上海购物APP于2020年正式发布',
        content: '上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布',
        date: '2020·01·01'
      }, {
        cover: require('./resources/image/2.png'),
        title: '上海购物APP于2020年正式发布',
        content: '上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布上海购物APP于2020年正式发布',
        date: '2020·01·01'
      }]
    };
  },
  mounted() {
    this.$axios({
      method: 'get',
      url: 'https://api.shopping-shanghai.com/api/v1/download_url',
      params: {}
    }).then(response => {
      let result = response.data;
      if (result.code === 0) {
        this.download = result.data;
      }
    }).catch(error => {
      console.log(error);
    });
    window.onscroll = () => {
      this.fixedFlag = window.scrollY > document.getElementById('header').clientHeight;
    }
  },
  methods: {
    onClickNav(index) {
      this.selectedNav = index;
      window.location.hash = this.nav[index].id;
    },
    onClickOriginal(item) {
    }
  }
}
</script>