let prefix = 'https://api.shopping-shanghai.com';

if (process.env.NODE_ENV === 'test') {
    prefix = 'https://testapi.shopping-shanghai.com';
}

let config = {
    host: `${prefix}/api/v1`,
    url: `${prefix}/h5/seven/#/`,
    site: `${prefix}/api/v1`,
    download: {
        text: '即将离开页面，打开“上海购物”',
        link: 'https://www.pgyer.com/shgw'
    },
    share: {
        title: '7天退换',
        desc: '以诚交心，舒适在心，欢在此，换在此',
        link: `${prefix}/h5/seven/`,
        imgUrl: 'https://wxcdn.kdcer.com/shgwj/kdmvp/share.png'
    }
};

export default config;